import axios from 'axios'

var createResultRow = function () {
  return {
    default: {
      x: {
        value: 0,
        timestamp: null,
      },
      y: {
        value: 0,
        timestamp: null,
      },
      z: {
        value: 0,
        timestamp: null,
      },
      ca: {
        value: 0,
        timestamp: null,
      },
    },
    gravity: {
      x: {
        value: 0,
        timestamp: null,
      },
      y: {
        value: 0,
        timestamp: null,
      },
      z: {
        value: 0,
        timestamp: null,
      },
      ca: {
        value: 0,
        timestamp: null,
      },
    },
  }
}

var resultsData = []
resultsData.push(createResultRow())

const state = {
  user: null,
  subject: null,
  started_at: [],
  countdown: 0,
  measurement_time: [],
  results: resultsData,
  currentIndex: 0,
  registerState: 0,
}

const mutations = {
  practitioner(state, payload) {
    state.user = payload
  },

  subject(state, payload) {
    state.subject = payload
  },

  startedAt(state, payload) {
    state.started_at[state.currentIndex] = payload
  },

  measurementTime(state, payload) {
    state.measurement_time[state.currentIndex] = payload
  },

  countDown(state, payload) {
    state.countDown = payload
  },

  updateResult(state, payload) {
    state.results[state.currentIndex][payload.type][payload.direction].value =
      payload.value
    state.results[state.currentIndex][payload.type][
      payload.direction
    ].timestamp = payload.timestamp
  },
  updateRegisterState(state, payload) {
    state.registerState = payload
  },
}

const getters = {
  measurementDataList(state) {
    return state.results
  },
  absDefaultValueX(state) {
    return Math.abs(state.results[state.currentIndex].default.x.value)
  },
  absDefaultValueY(state) {
    return Math.abs(state.results[state.currentIndex].default.y.value)
  },
  absDefaultValueZ(state) {
    return Math.abs(state.results[state.currentIndex].default.z.value)
  },
  absDefaultValueCA(state) {
    return Math.abs(state.results[state.currentIndex].default.ca.value)
  },
  absGravityValueX(state) {
    return Math.abs(state.results[state.currentIndex].gravity.x.value)
  },
  absGravityValueY(state) {
    return Math.abs(state.results[state.currentIndex].gravity.y.value)
  },
  absGravityValueZ(state) {
    return Math.abs(state.results[state.currentIndex].gravity.z.value)
  },
  absGravityValueCA(state) {
    return Math.abs(state.results[state.currentIndex].gravity.ca.value)
  },
  measurementCount(state) {
    return state.currentIndex
  },
  subjectId(state) {
    return state.subject
  },
}

const actions = {
  create(context) {
    context.dispatch('resetResult')
    context.state.results.push(createResultRow())
  },

  setupConfig(context, payload) {
    context.commit('countDown', payload.countDown)
    context.commit('startedAt', payload.startedAt)
    context.commit('measurementTime', payload.measurementTime)
  },

  redo(context) {
    context.state.results.pop()
    context.state.results.push(createResultRow())
  },

  updateResult(context, payload) {
    console.log(payload)
    context.commit('updateResult', payload)
  },

  resetResult(context) {
    context.state.subject = null
    context.state.currentIndex = 0
    context.state.started_at.splice(-context.state.started_at.length)
    context.state.measurement_time.splice(
      -context.state.measurement_time.length,
    )
    context.state.results.splice(-context.state.results.length)
    console.log('results', context.state.results)
  },

  next(context) {
    context.state.currentIndex += 1
    if (context.state.results.length < context.state.currentIndex + 1) {
      context.state.results.push(createResultRow())
    }
  },

  prev(context) {
    if (context.state.currentIndex > 0) {
      context.state.currentIndex -= 1
    }
  },

  start(context, payload) {
    context.commit('startedAt', payload)
  },

  removeAt(context, payload) {
    context.state.results.splice(payload, 1)
  },

  async sendToServer(context, user) {
    const _f = new FormData()
    _f.append('user', user)
    _f.append('subject', context.state.subject)
    _f.append('started_at', JSON.stringify(context.state.started_at))
    _f.append('countdown', context.state.countdown)
    _f.append(
      'measurement_time',
      JSON.stringify(context.state.measurement_time),
    )
    _f.append('results', JSON.stringify(context.state.results))

    // const response = await axios.post('api/measurement', _f)
    // context.commit('updateRegisterState', response.state)
    return await axios.post('api/measurement', _f)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}

import { createStore } from 'vuex'
import measurement from './modules/measurement'

export default createStore({
  modules: {
    measurement: measurement,
  },
  state: {
    isLoign: false,
    user: null,
    subjects: [],
  },
  mutations: {
    setUser(state, payload) {
      state.user = JSON.parse(JSON.stringify(payload))
    },
    fillSubjects(state, payload) {
      state.subjects = JSON.parse(JSON.stringify(payload))
    },
    addSubject(state, payload) {
      state.subjects.push(payload)
    },
  },
  getters: {
    userName(state) {
      return state.user ? state.user.name : ''
    },
    userID(state) {
      return state.user ? state.user.id : null
    },
    subjectList(state) {
      return state.subjects
    },
  },
  actions: {
    fillSubjects(store, value) {
      store.commit('fillSubjects', value)
    },
    addSubject(store, value) {
      store.commit('addSubject', value)
    },
    setUser(context, value) {
      context.commit('setUser', value)
    },
  },
})

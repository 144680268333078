//import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/login'),
  },
  {
    path: '/home',
    name: 'top',
    component: () => import('@/views/pages/top'),
    meta: { requiresAuth: true },
  },
  {
    path: '/measurement',
    name: 'count',
    component: () => import('@/views/pages/count'),
    meta: { requiresAuth: true },
  },
  {
    path: '/result',
    name: 'result',
    component: () => import('@/views/pages/result'),
    meta: { requiresAuth: true },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/register'),
    meta: { requiresAuth: true },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.state.isLoign === false) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
